import { useChat } from '@components/chat/useChat';
import { wrap } from '@sitecore/common';
import { TitleTextCTARendering } from '@sitecore/types/manual/TitleTextCTA';
import { Button, TextLink } from '@sparky';

type Props = {
  chatLink: TitleTextCTARendering['fields']['chatLink'];
  linkColor: TitleTextCTARendering['params']['linkColor'];
};

export const ChatLink: React.FC<Props> = ({ chatLink, linkColor }) => {
  try {
    const [mount] = useChat();
    const content = wrap({ editable: chatLink?.editable, value: chatLink?.value.text });
    const clickHandler = () => mount({ visibility: 'open', topic: chatLink?.value?.chattype });

    if (chatLink?.value?.styling === 'button') {
      return <Button onClick={clickHandler}>{content}</Button>;
    }
    return (
      <TextLink onClick={clickHandler} color={linkColor} emphasis="high">
        {content}
      </TextLink>
    );
    //Do not render anything if useChat throws an error
  } catch {
    return null;
  }
};
