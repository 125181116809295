import { createElement, FC, FunctionComponent, ReactNode } from 'react';

import parse, { DOMNode, Element, attributesToProps, domToReact } from 'html-react-parser';

import { useLinkComponent } from '@link';
import { BulletList, NumberedList, Text, TextLink } from '@sparky';

import ChatLinkButton from '../chat/ChatLinkButton';

type SupportedDefaultReplacements = 'a' | 'p' | 'strong' | 'span' | 'ul' | 'ol' | 'li' | 'button';
type SupportedOverrideReplacements = SupportedDefaultReplacements | 'h3' | 'h4' | 'h5' | 'h6';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DefaultReplacements = Record<SupportedDefaultReplacements, FunctionComponent<any>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OverrideReplacements = Partial<Record<SupportedOverrideReplacements, FunctionComponent<any>>>;

type AnchorProps = {
  children: ReactNode;
  className?: string;
  href: string;
  target?: string;
};

const Anchor: FC<AnchorProps> = ({ children, className, href, target, ...props }) => {
  const Link = useLinkComponent();

  // This is an exception to allow adCall classNames only on anchor tags
  const filteredClassNames = className
    ?.split(' ')
    .filter(string => string.includes('calltracker'))
    .join(' ');

  return (
    // @ts-ignore cannot apply className
    <Link className={filteredClassNames} href={href} linkType="external">
      <TextLink target={target} {...props}>
        {children}
      </TextLink>
    </Link>
  );
};

const Paragraph: typeof Text = props => <Text as="p" {...props} />;

const Strong: typeof Text = props => <Text weight="bold" {...props} />;

const UnorderedList: typeof BulletList = props => <BulletList {...props} />;
UnorderedList.Item = props => <BulletList.Item {...props} />;

const Button: typeof ChatLinkButton = props => {
  const { 'data-chattopic': chatTopic } = props;

  if (chatTopic) {
    return <ChatLinkButton {...props} />;
  }

  return null;
};

const Span: typeof Text = props => {
  return <Text whiteSpace={'data-unwrappable' in props ? 'nowrap' : undefined} {...props} />;
};

const defaultReplacements: DefaultReplacements = {
  a: Anchor,
  p: Paragraph,
  span: Span,
  strong: Strong,
  ul: UnorderedList,
  li: UnorderedList.Item,
  ol: NumberedList,
  button: Button,
};

const replace = (
  node: DOMNode,
  overrideReplacements?: OverrideReplacements,
): JSX.Element | object | void | undefined | null | false => {
  const replacements = { ...defaultReplacements, ...overrideReplacements };

  const { name, attribs, children } = node as Element;

  if (!node) return;

  if (name && name in replacements) {
    return createElement(
       
      replacements[name as SupportedOverrideReplacements]!,
      attributesToProps(attribs),
      domToReact(children as DOMNode[], {
        replace: (node: DOMNode) => replace(node, replacements),
      }),
    );
  }
};

type RichTextProps = {
  html: string | undefined;
  replacements?: OverrideReplacements;
};

const RichText = ({ html, replacements = {} }: RichTextProps) =>
  html ? <>{parse(html, { replace: node => replace(node, replacements) })}</> : null;

export default RichText;
